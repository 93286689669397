import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import propTypes from 'prop-types'

import { Box, Button, Paper, Typography } from '@material-ui/core'

import useStyles from './style'
// Heroes Background
import HeroesBg from '../../../static/images/heroes-bg.jpg'
import HeroesStoreBg from '../../../static/images/heroes-bg-map-websize.gif'
import HeroesStoreBgSmall from '../../../static/images/heroes-bg-map-mobilesize.gif'

const Heroes = props => {
  const classes = useStyles(props)
  const {
    children,
    isHomePage,
    isServicePage,
    isStorePage,
    minHeight,
    homeHeading,
    homeDesc,
    banner,
    button1,
    button2,
    serviceHeading,
    serviceDesc,
  } = props
  // banner itu url banner

  let heading, caption, currentBg

  // console.log('props', props)

  // const currentPage = () => {
  //   if(isHomePage){
  //     return HeroesBg
  //   }else if(isServicePage){
  //     return HeroesStoreBg
  //   }else if(isStorePage){
  //     return HeroesStoreBgSmall
  //   }
  // }

  if (isHomePage) {
    heading = homeHeading
    caption = homeDesc
  } else if (isServicePage) {
    heading = serviceHeading
    caption = serviceDesc
  }
  return (
    <Paper
      className={classNames(
        classes.root,
        isServicePage ? classes.isServicePage : undefined,
        isHomePage ? classes.isHomePage : undefined,
        isStorePage ? classes.isStorePage : undefined
      )}
      {...props}
      style={{
        // background: isStorePage ? `url(${HeroesStoreBg})`:`url(${HeroesBg})`,
        // background: `url(${HeroesBg})`,
        minHeight: minHeight,
      }}
    >
      {/* Children = Navmenu */}
      {children}
      <Box className={classes.contentWrapper}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb="16px">
            <Typography className={classes.heading} gutterBottom align="center">
              {heading}
            </Typography>
          </Box>
          <Box mb="40px">
            <Typography className={classes.caption} align="center">
              {caption}
            </Typography>
          </Box>
        </Box>
        {isHomePage && (
          <Box display="flex" justifyContent="center">
            <Box width="280px" display="flex" justifyContent="space-around">
              <Link to={button1.link} className={classes.buttonLink}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classNames(
                    classes.heroesButton,
                    classes.ourServiceButton
                  )}
                  disableElevation
                >
                  {button1.text}
                </Button>
              </Link>
              <Link to={button2.link} className={classes.buttonLink}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classNames(
                    classes.heroesButton,
                    classes.ourStoriesButton
                  )}
                  disableElevation
                >
                  {button2.text}
                </Button>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Paper>
  )
}

Heroes.propTypes = {
  isHomePage: propTypes.bool,
  isServicePage: propTypes.bool,
  isStorePage: propTypes.bool,
  minHeight: propTypes.string,
  imgBackground: propTypes.string,
  imgResponsiveBg: propTypes.string,
}

export default Heroes

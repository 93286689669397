import { makeStyles } from '@material-ui/core/styles'
import HeroesBg from '../../../static/images/heroes-bg.jpg'
import HeroesStoreBg from '../../../static/images/heroes-bg-map-websize.gif'
import HeroesStoreBgSmall from '../../../static/images/heroes-bg-map-mobilesize.gif'

const appBarHeight = 100
const mainPadding = 10
const buttonMargin = 8
const smallFont = 18

const useStyles = makeStyles(theme => ({
  /* Main Layout */

  root: props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    // color: (props) => console.log('props', props),
    // background: `url(${HeroesBg})`,
    background: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url(${props.imgBackground})`,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }),
  isStorePage: props => ({
    backgroundImage: `url(${props.imgBackground})`,

    backgroundPosition: 'center',
    // backgroundSize:'contain',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url(${props.imgResponsiveBg})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      minHeight: '90vh !important',
    },
  }),
  contentWrapper: {
    marginTop: appBarHeight,
    [theme.breakpoints.down('sm')]: {
      marginTop: appBarHeight / 2,
      padding: mainPadding,
    },
  },
  /* Large Heroes */
  // isLarge: {
  //   minHeight: "100vh",
  // },
  // /* Medium Heroes */
  // isMedium: {
  //   minHeight: "31.25rem",
  // },

  heading: {
    color: '#FFF',
    fontFamily: 'roosevelt02',
    textTransform: 'uppercase',
    maxWidth: '31.25rem',
    fontSize: '3.5rem',
    lineHeight: '4.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '3.25rem',
      lineHeight: '3.125rem',
    },
  },

  caption: {
    maxWidth: '34.375rem',
    color: '#FFF',
    opacity: '80%',
    lineHeight: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: smallFont,
    },
  },

  heroesButton: {
    borderRadius: 0,
    textTransform: 'capitalize',
    color: '#FFF',
    width: 147,
    height: 51,
  },
  buttonLink: {
    textDecoration: 'none',
  },

  ourServiceButton: {
    marginRight: buttonMargin,
  },

  ourStoriesButton: {
    marginLeft: buttonMargin,
    border: '2px solid #D28F00',
  },
}))

export default useStyles
